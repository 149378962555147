<template>
    <div>
        <div class="header pb-8 pt-2 pt-lg-6 d-flex align-items-center">
            <!-- Mask -->
            
            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">

                <div class="col-lg-7 p-0 col-md-10">
                    <h3 class="mb-0 text-white">Detail faktúry</h3>
                    <h2 class="">Objednávka č.:{{id}}</h2>
                </div>
            </div>
        </div>
        
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-7">
                    <a-card shadow type="secondary">
                     
                        <template>
                            <form @submit.prevent="handleSubmit">
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <pre>{{ role }}</pre>
                                        </div>     </div>     </div> 
                            </form>
                        </template>
                    </a-card>
                </div> 
                <div class="col-xl-5" v-if="role.url">
                    <iframe :src="role.url+'#toolbar=0&view=FitV'" width="100%" height="904" frame="0" style="border:0"></iframe>
                   </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {API_URL} from "../api";
    import {authHeader} from "../_helpers";
    import {dataService} from "../_services";
    import Vue from "vue"
    import FormItem from 'ant-design-vue'
    import PDFViewer from 'pdf-viewer-vue/dist/vue2-pdf-viewer'
    Vue.use(FormItem);


    export default {
        name: 'level',
        props: ['id'],

        data() {
            return {
                head: authHeader(),
                send: API_URL,
                permissions: [],
                colorsto: JSON.parse(localStorage.colors),
                formItemLayout: {
                    labelCol: {span: 6},
                    wrapperCol: {span: 14},
                },
                base64:'',
                roleId: {
                    uid: this.id
                },
                role: {
                    title: '',
                },
            }
        },
        methods: {
            handleSubmit() {
                let $role_data = this.role;
                if(this.id !== "add")
                    $role_data["id"] = this.id;
                dataService.axiosPost(this.$options.name, $role_data).then(results => {
                    if (results.data.message) {
                        this.$router.push("/" + this.$options.name + "/" + results.data.message);
                    }
                });
            },
        },
        components: {
    PDFViewer,
  },
        mounted() {
            if (!isNaN(this.id)) {

                


                dataService.axiosFetch('invoicedata', this.id).then(results => {
                    this.role = results;
 
                 //   this.role.url = 'https://api.betared.sk/' + this.role.pdf;
                });
            }
        }
    };
</script>
<style>

    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }
    .ant-checkbox-group-item
    {display:inline-block;width:100%; margin-bottom:10px;}

    img.placeholder{
        width:500px;
    }

    .viewer-container .viewer-content >>> .viewer-item>div
    {min-width:500px!important;}
</style>
